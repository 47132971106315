import React, { CSSProperties, FC, ReactNode, useEffect, useState } from "react";
import { ChartConfiguration } from "../../chart/ChartConfiguration";
import { getMetricData } from "../../../services/services";
import { MetricsResponseType } from "../../../utils/types/types";
import { ResponseType } from "../../../utils/types/uiTypes";
import { path } from "ramda";
import { Spin } from "antd";
import { COLORS } from "../../../utils/general/colors";
import { ChartWidgetType, ParameterDataType } from "../../../utils/types/enums";
import { displayDashAsDefault, getFloatValue } from "../../../utils/general/helper";
import { SingleValue } from "./SingleValue";

const getResults: getResultsType = (dataType, value = "--") => {
  switch (dataType) {
    case "DOUBLE":
      return displayDashAsDefault(getFloatValue(value, 2));
    default:
      return value;
  }
};

export const Widget: FC<WidgetType> = function ({ onClick = (): void => {}, outerStyle, outerClassName = "", deviceId, from, to, expressions = [], type, height = 250, title = "", allowExport = false, stock = false, colors = COLORS[0].values, yThreshold = false }: WidgetType) {
  const [loading, setLoading] = useState<boolean>(true);
  const [metricResponse, setMetricResponse] = useState<MetricsResponseType | null>(null);
  const getWidget: getWidgetType = (metrics = []) => {
    switch (type) {
      case "SINGLE_VALUE":
        return <SingleValue title={title} value={getResults(path([0, "parameter", "dataType"], metrics) as ParameterDataType, path([0, "results", 0, "stringValue"], metrics))} units={path([0, "parameter", "unit"], metrics)} tag={path([0, "parameter", "tag"], metrics)} />;
      default:
        return (
          <div className={"flex h-full w-full"}>
            <ChartConfiguration height={height} title={title} type={type} metrics={metrics} min={from} max={to} allowExport={allowExport} stock={stock} colors={colors} yThreshold={yThreshold} />
          </div>
        );
    }
  };

  const getMetricInformation: geMetricInformationType = (id, from, to, expressions) => {
    getMetricData({
      segments: {
        id,
      },
      params: {
        from,
        to,
        metric: expressions,
      },
    })
      .then(({ data }: ResponseType<MetricsResponseType>) => {
        const { from, to, deviceId, metrics = [] }: MetricsResponseType = data;
        setMetricResponse({ from, to, deviceId, metrics });
      })
      .catch(() => {
        setMetricResponse(null);
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetricInformation(deviceId, from, to, expressions);
  }, [deviceId, from, to, expressions]);

  return (
    <div onClick={onClick} className={`relative h-full w-full ${outerClassName} bg-white`} style={outerStyle}>
      {loading ? (
        <div className={"h-full flex justify-center"}>
          <Spin className={"m-auto"} />
        </div>
      ) : (
        metricResponse && getWidget(metricResponse.metrics)
      )}
    </div>
  );
};

type WidgetType = {
  onClick?: () => void;
  outerStyle?: CSSProperties;
  outerClassName?: string;
  deviceId: string;
  from: number;
  to: number;
  expressions: Array<string>;
  type: ChartWidgetType;
  height?: number;
  title?: string;
  allowExport?: boolean;
  stock?: boolean;
  colors?: Array<string>;
  yThreshold?: number | boolean;
};

type MetricType = {
  parameter: ParameterType;
  results: Array<ParameterValueType>;
};

type ParameterType = {
  tag: string;
  name: string;
  unit?: string;
  dataType: "LONG" | "DOUBLE" | "BOOLEAN" | "STRING" | "UNDEFINED";
};

type ParameterValueType = {
  stringValue: string;
  timestamp: number;
};

type geMetricInformationType = (deviceId: string, from: number, to: number, expressions: Array<string>) => void;
type getResultsType = (dataType: ParameterDataType, value?: string) => string;
type getWidgetType = (metrics: Array<MetricType>) => ReactNode;
