import React, { FC } from "react";
import { JobStatusType } from "../../../utils/types/enums";
import { JobsList } from "../../job/JobsList";
import { useParams } from "react-router";
import { usePageTitle } from "../../../custom-hooks/usePageTitle";
import { Space } from "antd";

export const DeviceJobs: FC = function () {
  usePageTitle("Jobs");
  const { deviceId }: ParamsType = useParams();

  return (
    <div className={"relative mx-auto h-full max-h-full overflow-y-auto hide-scrollbar flex flex-col p-6"}>
      <Space direction={"vertical"} className={"gap-4 w-full"}>
        <JobsList size={200} deviceId={deviceId} title={<div className={"font-medium"}>Queue</div>} statuses={[JobStatusType.IN_PROGRESS, JobStatusType.PAUSED, JobStatusType.QUEUED]} />
        <JobsList size={20} deviceId={deviceId} title={<div className={"font-medium"}>History</div>} statuses={[JobStatusType.COMPLETED, JobStatusType.FAILED, JobStatusType.ABORTED, JobStatusType.WAITING, JobStatusType.UNKNOWN]} />
        <JobsList size={20} deviceId={deviceId} title={<div className={"font-medium"}>Cancelled Jobs</div>} statuses={[JobStatusType.CANCELLED]} />
      </Space>
    </div>
  );
};

type ParamsType = {
  deviceId: string;
};
