import React, { FC, ReactNode, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { getDevice, getDeviceDashboard, saveDashboard } from "../services/services";
import { Breadcrumb, Button, Empty, Input, message, Result, Space, Tooltip } from "antd";
import { DashboardSectionType, DeviceDashboardType, DeviceType, PaginationContainer } from "../utils/types/types";
import { Section } from "../components/device/metrics/Section";
import { reflowAllCharts } from "../utils/charts";
import { clone, equals, isEmpty, isNil } from "ramda";
import { DateRangePicker } from "../components/general/DateRangePicker";
import { creatNewSection } from "../utils/dashboardCustomization";
import { insertItemIntoArray, moveItemInArray, removeItemFromArray, updateItemInArray, valueOrDefault } from "../utils/general/helper";
import { DeviceDashboardProvider } from "../context/DeviceDashboardContext";
import { ErrorType, handleErrorType, MenuItemType, NoParamVoidType, ResponseType } from "../utils/types/uiTypes";
import { HOME_PAGE_URL } from "../utils/routes/config";
import { usePageTitle } from "../custom-hooks/usePageTitle";
import { PageLayout } from "../components/layout/PageLayout";
import { EditableSection } from "../components/device/metrics/customization/EditableSection";
import { PRIMARY_BUTTON_STYLE } from "../utils/general/styles";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { MenuNavigation } from "../components/layout/MenuNavigation";
import Title from "antd/lib/typography/Title";
import { DeviceMonitors } from "../components/device/details/DeviceMonitors";
import { IconButton } from "../components/general/IconButton";
import { ConfirmDeleteModal } from "../utils/modals";
import { useConfirmReload } from "../custom-hooks/useConfirmReload";
import { useAutoRefresh } from "../custom-hooks/useAutoRefresh";

export const DeviceMetrics: FC = function () {
  usePageTitle("Metrics");
  const { id }: ParamsType = useParams();
  const location = useLocation();
  const history = useHistory();
  const [activeSection, setActivesSection] = useState<string>(new URLSearchParams(location.search).get("section") ?? "0");
  const [error, setError] = useState<ErrorType | null>(null);
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [dates, setDates] = useState<[number, number]>([moment().subtract(5, "minutes").valueOf(), moment().valueOf()]);
  const [dashboard, setDashboard] = useState<DeviceDashboardType | null>(null);
  const [customizedDashboard, setCustomizedDashboard] = useState<Array<DashboardSectionType> | null>(null);
  const [device, setDevice] = useState<DeviceType | null>(null);

  useConfirmReload(edit ? !equals(customizedDashboard, dashboard?.sections) : false, [customizedDashboard, edit, dashboard]);

  const getErrorContent: getErrorContentType = (error) => {
    return (
      <Result
        className={"w-full"}
        status={500}
        title={valueOrDefault("", error?.error?.status)}
        subTitle={error?.message}
        extra={
          <>
            <Button
              onClick={(): void => {
                window.location.reload();
              }}
            >
              Try Again!
            </Button>
            <Button
              type={"primary"}
              className={"bg-primary-500 border-primary-500 hover:bg-opacity-80 border-opacity-80"}
              onClick={(): void => {
                history.push(HOME_PAGE_URL);
              }}
            >
              Back Home
            </Button>
          </>
        }
      />
    );
  };

  const handleError: handleErrorType = (msg, err) => {
    setError({
      error: err,
      message: msg,
    });
  };

  const onDateChange: onDateChangeType = (dates) => {
    setDates(dates);
    const params = new URLSearchParams(location.search);
    params.set("from", dates[0].toString());
    params.set("to", dates[1].toString());
    history.push({
      pathname: `/devices/${id}/metrics`,
      search: params.toString(),
    });
  };

  const onCancel: NoParamVoidType = () => {
    setEdit(false);
    setCustomizedDashboard(null);
  };

  const onEdit: NoParamVoidType = () => {
    setEdit(true);
    const customizedDashboard: DashboardSectionType[] = isNil(dashboard) || isNil(dashboard.sections) || isEmpty(dashboard.sections) ? [creatNewSection()] : clone(dashboard.sections);
    setCustomizedDashboard(customizedDashboard);
  };

  const onSave: NoParamVoidType = () => {
    setSaving(true);
    saveDashboard({
      segments: {
        deviceId: id,
        id: dashboard?.id,
      },
      body: JSON.stringify({ sections: customizedDashboard }),
    })
      .then(({ data }: ResponseType) => {
        message.success("Dashboard has been saved successfully");
        const { id, device, sections = [] }: DeviceDashboardType = data;
        setDashboard({ id, device, sections });
        setSaving(false);
        onCancel();
      })
      .catch(() => {
        message.error("Unable to save the dashboard");
        setSaving(false);
      });
  };

  const getDeviceData: getByDeviceIdType = (id) => {
    getDevice({
      segments: {
        id,
      },
    })
      .then(({ data }: ResponseType<DeviceType>) => {
        setDevice(data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const getDashboardData: getByDeviceIdType = (id) => {
    getDeviceDashboard({
      segments: {
        id,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<DeviceDashboardType>>) => {
        const { id, device, sections = [] }: DeviceDashboardType = data?.results[0];
        setDashboard({ id, device, sections });
      })
      .catch((error: ResponseType) => {
        console.log(error);
        handleError("Unable to load device dashboard", error);
      })
      .then(() => {
        setLoadingDashboard(false);
      });
  };

  const reflowChartsTimeout: NoParamVoidType = () => {
    setTimeout(() => {
      reflowAllCharts();
    });
  };

  const getExtraContent: getExtraContentType = (edit) => {
    return (
      <Space className={"gap-2 mx-5 mb-1"}>
        <DateRangePicker onChange={onDateChange} />
        {edit ? (
          <>
            <Button className={PRIMARY_BUTTON_STYLE} disabled={saving} loading={saving} onClick={onSave}>
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </>
        ) : (
          <Button className={PRIMARY_BUTTON_STYLE} onClick={onEdit}>
            Edit
          </Button>
        )}
      </Space>
    );
  };

  const getMenuItems: getMenuItemsType = (sections: Array<DashboardSectionType>) => {
    return sections.map(({ name }, i) => {
      return {
        Component: DeviceMonitors,
        path: `/devices/:deviceId/metrics?section=${i}`,
        exact: false,
        title: name,
        key: i.toString(),
        permission: true,
        hoverPrefix: edit && i !== 0 && (
          <IconButton
            type={"primary"}
            size={"sm"}
            onClick={(): void => {
              customizedDashboard && setCustomizedDashboard(moveItemInArray(i, i - 1, customizedDashboard));
              setActivesSection((i - 1).toString());
            }}
          >
            <i className="fa fa-angle-left"></i>
          </IconButton>
        ),
        hoverSuffix: edit && i + 1 !== customizedDashboard?.length && (
          <IconButton
            type={"primary"}
            size={"sm"}
            onClick={(): void => {
              customizedDashboard && setCustomizedDashboard(moveItemInArray(i, i + 1, customizedDashboard));
              setActivesSection((i + 1).toString());
            }}
          >
            <i className="fa fa-angle-right"></i>
          </IconButton>
        ),
      };
    });
  };

  const getTitle = () => {
    return (
      <div className={"flex justify-between items-center mb-1"}>
        <div className={"flex flex-row items-center"}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Title level={5} className={"inline-block text-dark-700 mb-0"}>
                {device?.name}
              </Title>
            </Breadcrumb.Item>
            {edit ? (
              <>
                {customizedDashboard && activeSection && customizedDashboard[Number(activeSection)] && (
                  <Breadcrumb.Item className={"group"}>
                    <span className={"inline-block"}>
                      <Input
                        value={customizedDashboard[Number(activeSection)].name}
                        onChange={(e): void => {
                          const index = Number(activeSection);
                          customizedDashboard && activeSection && setCustomizedDashboard(updateItemInArray(index, customizedDashboard, { ...customizedDashboard[index], name: e.currentTarget.value }));
                        }}
                      />
                    </span>
                  </Breadcrumb.Item>
                )}
              </>
            ) : (
              <>{dashboard && activeSection && dashboard?.sections[Number(activeSection)] && <Breadcrumb.Item className={"group"}>{valueOrDefault(dashboard?.sections[Number(activeSection)]?.name, "")}</Breadcrumb.Item>}</>
            )}
          </Breadcrumb>
          <Space className={"ml-2 gap-0"}>
            {edit && customizedDashboard && (
              <div>
                {activeSection && customizedDashboard[Number(activeSection)] && (
                  <IconButton
                    icon={"trash"}
                    tooltipText={"Delete Section"}
                    type={"danger"}
                    onClick={(): void => {
                      ConfirmDeleteModal(
                        "Are you sure you want to delete this section?",
                        () => {
                          const dashboard = removeItemFromArray(Number(activeSection), customizedDashboard);
                          setCustomizedDashboard(dashboard);
                          setActivesSection((dashboard?.length > 0 ? dashboard.length - 1 : 0).toString());
                        },
                        <div className={"pt-3"}>Name: {customizedDashboard[Number(activeSection)]?.name}</div>
                      );
                    }}
                  />
                )}
                <IconButton
                  tooltipText={"Add New Section"}
                  type={"default"}
                  onClick={(): void => {
                    setCustomizedDashboard(insertItemIntoArray(customizedDashboard?.length, customizedDashboard, creatNewSection()));
                  }}
                >
                  <PlusOutlined />
                </IconButton>
              </div>
            )}
          </Space>
        </div>
        <Button
          className={"px-0"}
          type={"link"}
          onClick={(): void => {
            history.push(`/devices/${device?.id}/overview`);
          }}
          icon={<SettingOutlined />}
        >
          View Device Overview
        </Button>
      </div>
    );
  };
  useEffect(() => {
    reflowChartsTimeout();
  }, [customizedDashboard]);

  useEffect(() => {
    getDeviceData(id);
    getDashboardData(id);
  }, [id]);

  useAutoRefresh(
    () => {
      const dateRange: [number, number] = [moment(dates[0]).add(30, "seconds").valueOf(), moment(dates[1]).add(30, "seconds").valueOf()];
      onDateChange(dateRange);
    },
    !edit,
    [edit],
    30000
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("section", activeSection);

    history.push({
      pathname: `/devices/${id}/metrics`,
      search: params.toString(),
    });
  }, [id, activeSection]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get("from");
    const to = params.get("to");
    setDates((v) => [from ? Number(from) : v[0], to ? Number(to) : v[1]]);
  }, []);

  return (
    <DeviceDashboardProvider deviceId={id} fromDate={dates[0]} toDate={dates[1]}>
      {dashboard && dashboard.sections && (
        <MenuNavigation extra={getExtraContent(edit)} selectedKeys={activeSection ? [activeSection] : []} menu={getMenuItems(!edit ? dashboard?.sections : customizedDashboard ?? [])} title={getTitle()} routePath={(key): void => setActivesSection(key.key.toString())}>
          <PageLayout loading={loadingDashboard} loadingText={"Loading dashboard ..."} height={"full"} error={!isNil(error)} errorContent={getErrorContent(error)}>
            <>
              {edit ? (
                <>
                  {customizedDashboard ? (
                    customizedDashboard.map(
                      ({ id, name, rows }, i) =>
                        i.toString() === activeSection && (
                          <EditableSection
                            key={id}
                            rows={rows}
                            onChange={(rows): void => {
                              setCustomizedDashboard(updateItemInArray(i, customizedDashboard, { id, name, rows }));
                            }}
                          />
                        )
                    )
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>{dashboard && dashboard.sections.length > 0 ? dashboard.sections.map(({ rows }, i) => i.toString() === activeSection && <Section key={i} rows={rows} />) : <Empty className={"mt-20"} description={"No data"} image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>
              )}
            </>
          </PageLayout>
        </MenuNavigation>
      )}
    </DeviceDashboardProvider>
  );
};

type ParamsType = {
  id: string;
};
type getMenuItemsType = (sections: Array<DashboardSectionType>) => Array<MenuItemType>;
type getByDeviceIdType = (id: string) => void;
type onDateChangeType = (dates: [number, number]) => void;
type getExtraContentType = (edit: boolean) => ReactNode;
type getErrorContentType = (error: ErrorType | null) => ReactNode;
