import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button, Empty, Pagination, Result } from "antd";
import { getDevices, getDeviceSummaries } from "../services/services";
import {DeviceSummaryType, DeviceType, PaginationContainer} from "../utils/types/types";
import { ViewToggleSwitch } from "../components/general/ViewToggleSwitch";
import { DeviceCard } from "../components/device/dashboard/DeviceCard";
import { ErrorType, handleErrorType, ResponseType } from "../utils/types/uiTypes";
import { SearchBar } from "../components/general/SearchBar";
import {isEmpty, isNil, map, prop} from "ramda";
import { PageHeader } from "../components/general/PageHeader";
import { valueOrDefault } from "../utils/general/helper";
import { usePageTitle } from "../custom-hooks/usePageTitle";
import { PageLayout } from "../components/layout/PageLayout";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { HOME_PAGE_URL } from "../utils/routes/config";
import moment from "moment/moment";
import { useAutoRefresh } from "../custom-hooks/useAutoRefresh";
import { StatusFilter } from "../components/general/StatusFilter";
import { EquipmentStatusType } from "../utils/types/enums";

const GRID_VIEW_STYLE = "w-full mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4";
const LIST_VIEW_STYLE = "max-w-screen-sm md:max-w-screen-md lg:max-w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-none";

export const Devices: FC = function () {
  usePageTitle("Devices");
  const history = useHistory();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const [error, setError] = useState<ErrorType | null>(null);
  const [loadingDevices, setLoadingDevices] = useState<boolean>(true);
  const [gridView, setGridView] = useState<boolean>(true);
  const [summaries, setSummaries] = useState<Array<DeviceSummaryType>>([]);
  const [devicesContainer, setDevicesContainer] = useState<PaginationContainer<DeviceType> | null>(null);
  const [statuses, setStatuses] = useState<EquipmentStatusType[]>([])

  const getErrorContent: getErrorContentType = (error) => {
    return (
      <Result
        className={"w-full"}
        status={500}
        title={valueOrDefault("", error?.error?.status)}
        subTitle={"Oops, something went wrong!"}
        extra={
          <>
            <Button
              type={"default"}
              onClick={(): void => {
                history.replace(HOME_PAGE_URL + "?page=1&size=15");
                window.location.reload();
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
    );
  };
  const getHeaderContent: getHeaderContentType = (grid) => {
    return (
      <PageHeader title={"Devices"}>
        <div className={"hidden sm:flex justify-end gap-2 sm:pt-0"}>
          <SearchBar
            defaultValue={new URLSearchParams(location.search).get("query")?.toString()}
            onSearch={(query) => {
              urlSearchParams.set("query", query);
              urlSearchParams.set("page", (1).toString());
              history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
            }}
          />
          <StatusFilter
              value={new URLSearchParams(location.search).get("status")?.toString().split(",").filter(x => x.valueOf()!='')}
              onClick={(valueOrDefault) => {
                  setStatuses(valueOrDefault.map((value) => value as EquipmentStatusType));
                  urlSearchParams.set("status", valueOrDefault.toString());
                  urlSearchParams.set("page", (1).toString());
                  history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
              }}
          />
          <div className={"hidden lg:inline-flex"}>
            <ViewToggleSwitch gridView={grid} onClick={setGridView} />
          </div>
        </div>
      </PageHeader>
    );
  };

  const handleError: handleErrorType = (message, err) => {
    setError({
      error: err,
      message: message,
    });
  };

  const setPager = (page = 1, size = 15) => {
    urlSearchParams.set("page", page.toString());
    urlSearchParams.set("size", size.toString());
    history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
  };

  const getDeviceData: getDeviceDataType = (query, page = 0, size = 15, statuses) => {
    getDevices({
      params: {
        query,
        page: page - 1,
        size,
        status: statuses
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<DeviceType>>) => {
        const { results = [], page } = data;
        setDevicesContainer({ results, page });
        getSummaries({ results, page });
      })
      .catch((error: ResponseType) => {
        console.log(error);
        handleError("Unable to load devices at this time", error);
      })
      .then(() => {
        setLoadingDevices(false);
      });
  };

  const getSummaries: getSummariesType = (container) => {
    const ids = container?.results ? map(prop('id'), container.results) : [];
    if(!isEmpty(ids)) {
      getDeviceSummaries({
        params: {
          deviceIds: ids.toString(),
          from: moment(Date.now()).subtract(1, "h").valueOf(),
          to: moment(Date.now()).valueOf(),
        }
      })
          .then(({data}:ResponseType<PaginationContainer<DeviceSummaryType>>) => {
            const { results = [], page } = data;
            console.log(results);
            setSummaries(results);
          })
          .catch((error: any) => {
            console.log("error")
            handleError("Unable to get summaries data", error);
          })
    }
  }

  useEffect(() => {
    setLoadingDevices(true);
    if (!(urlSearchParams.get("page") || urlSearchParams.get("size"))) {
      setPager();
    }
    getDeviceData(urlSearchParams.get("query") ?? "", Number(urlSearchParams.get("page")) ?? 0, Number(urlSearchParams.get("size")) ?? 15, urlSearchParams.get("status")?.split(',') ?? []);
  }, [search]);

    useAutoRefresh(
        () => {
            if (!(urlSearchParams.get("page") || urlSearchParams.get("size"))) {
                setPager();
            }
            getDeviceData(urlSearchParams.get("query") ?? "", Number(urlSearchParams.get("page")) ?? 0, Number(urlSearchParams.get("size")) ?? 15, urlSearchParams.get("status")?.split(',') ?? []);
        },
        true,
        [search]
    );

  return (
    <PageLayout loading={loadingDevices} loadingText={"Loading Devices ..."} error={!isNil(error)} errorContent={getErrorContent(error)} header={getHeaderContent(gridView)}>
      <>
        {devicesContainer && summaries && (
          <>
            {isEmpty(devicesContainer.results) ? (
              <Empty description={"No devices"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <>
                <div>
                  <span className={"text-gray-400 font-light text-sm"}>Showing {devicesContainer.results.length} devices</span>
                </div>
                <div className={`my-2 ${gridView ? GRID_VIEW_STYLE : LIST_VIEW_STYLE}`}>
                  {!isEmpty(summaries) && map((summary) => (
                    <div key={summary.device.id} className={"m-3"}>
                      <DeviceCard deviceSummary={summary} image={summary.device.model?.imageUrl} gridView={gridView} />
                    </div>
                  ), summaries)}
                </div>
              </>
            )}
            <Pagination hideOnSinglePage={false} defaultCurrent={0} className={"text-center mt-auto my-2"} onChange={setPager} pageSize={parseInt(devicesContainer.page.size)} current={parseInt(devicesContainer.page.number + 1)} total={parseInt(devicesContainer.page.size) * parseInt(devicesContainer.page.totalPages)} />
          </>
        )}
      </>
    </PageLayout>
  );
};

type getDeviceDataType = (query: string, page: number, size: number, statuses: string[]) => void;
type getErrorContentType = (error: ErrorType | null) => ReactNode;
type getHeaderContentType = (grid: boolean) => ReactNode;
type getSummariesType = (container: PaginationContainer<DeviceType>) => void;
